import { useParams } from "react-router-dom";
import React, {useState, useEffect, useRef} from "react";
import "./Page.css";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import {sessionDataActions} from "../../../store";
import {DataGrid} from "@mui/x-data-grid";
import {
    findCheckInParticipantByCode,
    findCheckInParticipantByParticipantID,
    getEventDetails,
    getParticipantList,
    UpdateParticipantAttendance
} from "../../../services/AWS/competitionInformation/competitionInfo";
import {useDispatch, useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import moment from "moment/moment";
import config from "../../../config.json";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const cardStyle = {
    fontFamily: "Arial",
    fontSize: "20px",
    marginLeft: "10%",
    marginRight: "10%",
};

const gridStyle = {
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
};



const CheckInPage = (props) =>{
    const [AuthorizedCheckIn, setAuthorizedCheckIn] = useState(false);
    const dispatch = useDispatch();
    const isWCALoggedin = useSelector(state => state.sessionData.isWCALoggedIn);
    const user = useSelector(state => state.sessionData.userInformation);
    const WCAuserLoggedInCheck = useSelector(state => state.sessionData.WCAuserLoggedInCheck);
    const {id} = useParams();
    const [ParticipantList, setParticipantList] = useState([]);
    const [FilteredList, setFilteredList] = useState([]);
    const [ParticipantInformation, setParticipantInformation] = useState({});
    const localStorageKey = (key) => `groups.${config.WCA_OAUTH_CLIENT_ID}.${key}`;
    const getLocalStorage = (key) => localStorage.getItem(localStorageKey(key));
    const [NameFilterValue, setNameFilterValue] = useState("");
    const [StatusBanner, setStatusBanner] = useState("");
    const [bannerColor, setBannerColor] = useState("green");
    const[ Remark, setRemark] = useState("");
    const [updateStatusButtonLoading,setUpdateStatusButtonLoading] = useState(false);

    // New loading states for each grid
    const [searchGridLoading, setSearchGridLoading] = useState(false);
    const [staffGridLoading, setStaffGridLoading] = useState(false);
    const [tshirtGridLoading, setTshirtGridLoading] = useState(false);
    const [merchantGridLoading, setMerchantGridLoading] = useState(false);

    const textFieldRef = useRef(null);

    // Convert participant info to rows

    const getParticipantProperty =  (property) => {
        if (ParticipantInformation.hasOwnProperty(property)) {
            if (property === "attended") {
                return ParticipantInformation[property] ? "Checked In" : "Not Checked In";

            }
            if (property === "checkInDateTime") {
                return ParticipantInformation[property] ? moment(ParticipantInformation[property]).format("YYYY-MM-DD HH:mm:ss").toLocaleUpperCase() : "";
            }
            if (property === "gender") {
                if (ParticipantInformation[property] === "m") {
                    return "Male"
                }
                if (ParticipantInformation[property] === "f") {
                    return "Female"
                }
                return ParticipantInformation[property];
            }

            return ParticipantInformation[property];
        }
        else{
            if (property === "attended" && ParticipantInformation.hasOwnProperty("ID") ) {
                return  "Not Checked In";

            }
            return "";
        }
        }
    const UpdateType = {
        "Participant": "Participant",
        "staff": "staff",
        "merchant": "merchant",
        "MYtshirt": "MYtshirt"
    }
    // Staff Data Grid Columns
    const staffColumns = [
        { field: 'fullName', headerName: 'Name', width: 200 },
        { field: 'role', headerName: 'Role', width: 150 },
        { field: '1st', headerName: '1st', width: 120 },
        { field: '2nd', headerName: '2nd', width: 120 },
        { field: '3rd', headerName: '3rd', width: 120 },
        { field: '4th', headerName: '4th', width: 120 },
        { field: 'TshirtSize', headerName: 'T-Shirt Size', width: 120 },
        { field: 'attended', headerName: 'Taken', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleStaffCheckIn(params.row)}
                >
                    {params.row.attended === true? "Undo":"Attend" }
                </Button>
            ),
        },
    ];

    // MYCSA T-Shirt Grid Columns
    const mycsaTshirtColumns = [
        { field: 'Name', headerName: 'Name', width: 200 },
        { field: 'size', headerName: 'Size', width: 100 },
        { field: 'type', headerName: 'Collection Type', width: 300 },
        { field: 'attended', headerName: 'Taken', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleTshirtCollection(params.row)}
                >
                    {params.row.attended === true? "Undo":"Collect" }
                </Button>
            ),
        },
    ];

    // Participant Info Grid Columns
    const participantColumns = [
        { field: 'fieldName', headerName: 'Field', width: 200 },
        { field: 'fieldValue', headerName: 'Value', width: 400 },
    ];

    // Handler functions for the new actions
    const handleStaffCheckIn =async (staffData) => {
        // Implement staff check-in logic
        console.log('Staff check-in:', staffData);
        setStaffGridLoading(true);
        await UpdateParticipantAttendance(staffData.ID,id,!staffData.attended,Remark,UpdateType.staff);
        ParticipantInformation.staffData.attended = !ParticipantInformation.staffData.attended;
        setParticipantInformation(ParticipantInformation);
        setStaffGridLoading(false);
    };

    const handleTshirtCollection =async (tshirtData) => {
        // Implement t-shirt collection logic
        console.log('T-shirt collection:', tshirtData);
        setTshirtGridLoading(true);
        await UpdateParticipantAttendance(tshirtData.ID,id,!tshirtData.attended,Remark,UpdateType.MYtshirt);
        await ParticipantInformation.mycsaTshirtImportData.forEach((item) => {
            if (item.ID === tshirtData.ID) {
                item.attended =!item.attended;
            }
        });
        setParticipantInformation(ParticipantInformation);
        setTshirtGridLoading(false);
    };

    const handleMerchantCollection =async (merchantData) => {
        // Implement merchant collection logic
        console.log('Merchant collection:', merchantData);
        setMerchantGridLoading(true);
        await UpdateParticipantAttendance(merchantData.ID,id,!merchantData.attended,Remark,UpdateType.merchant);
       await ParticipantInformation.merchantData.forEach((item) => {
            if (item.ID === merchantData.ID) {
                item.attended = !item.attended;
            }
        });
        setParticipantInformation(ParticipantInformation);
        setMerchantGridLoading(false);
    };


    const handleUpdateButtonClick =async (e,attended) => {

        if (ParticipantInformation.hasOwnProperty("ID") === false) {
            return;
        }
        if (attended === true && ParticipantInformation.hasOwnProperty("attended") && ParticipantInformation.attended === true) {
            return;
        }
        if (attended === false && ParticipantInformation.hasOwnProperty("attended") && ParticipantInformation.attended === false) {
            return;
        }

        setUpdateStatusButtonLoading(true);
        await UpdateParticipantAttendance(ParticipantInformation.ID,id,attended,Remark,UpdateType.Participant);
        ParticipantInformation.attended = attended;
        ParticipantInformation.checkInDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
        setParticipantInformation(ParticipantInformation);
        setStatusBanner("Check In Updated");
        setBannerColor("green");
        setUpdateStatusButtonLoading(false);
    }

    // Merchant Data Grid Columns
    const merchantColumns = [
        { field: 'Name', headerName: 'Name', width: 200 },
        { field: 'TshirtSize', headerName: 'TshirtSize', width: 150 },
        { field: 'Product', headerName: 'Product', width: 150 },
        { field: 'attended', headerName: 'Taken', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleMerchantCollection(params.row)}
                >
                    {params.row.attended === true? "Undo":"Collect" }
                </Button>
            ),
        },
    ];


    const columns = [
        {
            field: "Select",
            headerName: "Select",
            sortable: false,
            renderCell: (params) => {
                const onEditClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    const currentRow = params.row;
                    HandleGridSelectClick(currentRow)
                };

                return <Button onClick={onEditClick}>Select</Button>;
            }
        },
        { field: 'ID', headerName: 'ID', width: 100,visible:false },
        { field: 'UserName', headerName: 'Name', width: 400 },
        { field: 'WCAID', headerName: 'WCAID', width: 150 }]

    useEffect(() => {
        if (WCAuserLoggedInCheck === true) {
            GetCompetitionDetails(id);

        }
    }, [WCAuserLoggedInCheck]);

    const  getPartList =async (id) =>{
        const respondData = await getParticipantList(id);
        const eventList2 = respondData.list;
        setParticipantList(eventList2);

    }

    const GetCompetitionDetails = async (comp_id) => {
        dispatch(sessionDataActions.loading());
        let getcompdetails;
        if (isWCALoggedin) {
            getcompdetails = await getEventDetails(comp_id, user.email);

            //loop Register_Access
            if (getcompdetails.hasOwnProperty("Register_Access")) {
                const auth_list = getcompdetails.Register_Access.toString().split(";");
                for (var i = 0; i < auth_list.length; i++) {
                    if (auth_list[i] === user.email) {
                        setAuthorizedCheckIn(true);
                        break;
                    }
                }
            }
          await   getPartList(id);

        }
        dispatch(sessionDataActions.loaded());
    }

    const handleFilterTextChange = (e) => {
        //only take top 5 rows

        const value = e.target.value || '';
        setNameFilterValue(value);
        const lowerCaseValue = value.toLowerCase();
        const filteredData = ParticipantList.filter((row) =>
            row.UserName.toLowerCase().includes(lowerCaseValue)
        );
        filteredData.length = Math.min(filteredData.length, 5);
        setFilteredList(filteredData);
        if (value === '') {
            setFilteredList([]);
        }
    }

    const handleBarcodeFieldKeyDown = async (e) => {
        //if keycode enter then trigger search
        if (e.keyCode === 13 && e.target.value !== '') {
            dispatch(sessionDataActions.loading());
            const MYCAToken = getLocalStorage("MYCAaccessToken");
          const userData = await findCheckInParticipantByCode(e.target.value,id,MYCAToken);

          if (userData !== false){
                setParticipantInformation(userData);
                if (userData.hasOwnProperty("attended") && userData.attended === true) {
                    setBannerColor("red");
                    setStatusBanner("Participant Already Check in");
                }
                else {
                    setBannerColor("green");
                    setStatusBanner("Participant Found");
                }
              if (userData.hasOwnProperty("CheckInRemark")){
                  setRemark(userData.CheckInRemark);
              }
              else{
                  setRemark("");
              }
            }
            else {
                setBannerColor("red");
                setStatusBanner("Participant Not Found");
                setRemark("");
            }
          dispatch(sessionDataActions.loaded());
            e.target.value = '';
        }

    }

    const HandleGridSelectClick =async (row) => {
        dispatch(sessionDataActions.loading());
        const MYCAToken = getLocalStorage("MYCAaccessToken");
        const userData = await findCheckInParticipantByParticipantID(row.ID,id,MYCAToken);
        if (userData !== false){
            setParticipantInformation(userData);
            if (userData.hasOwnProperty("attended") && userData.attended === true) {
                setBannerColor("red");
                setStatusBanner("Participant Already Check in");
            }
            else {
                setBannerColor("green");
                setStatusBanner("Participant Found");
            }

            if (userData.hasOwnProperty("CheckInRemark")){
                setRemark(userData.CheckInRemark);
            }
            else{
                setRemark("");
            }
        }
        else {
            setBannerColor("red");
            setStatusBanner("Participant Not Found");
            setRemark("");
        }

        dispatch(sessionDataActions.loaded());
    }

    // Modified CheckIn component
    const CheckIn = (
        <Box sx={cardStyle}>
            <h2>Check In</h2>

            {/* Existing barcode and search fields */}
            <TextField
                type="text"
                className="inputField"
                placeholder="Scan barcode"
                autoFocus
                style={{height: "20px", fontSize: 16}}
                inputRef={textFieldRef}
                onKeyDown={(e) => handleBarcodeFieldKeyDown(e)}
            />

            <h4>Search By WCA Name</h4>
            <TextField
                style={{height: "20px", fontSize: 16}}
                type="text"
                className="inputField"
                placeholder="Search By WCA Name"
                onChange={(e) => handleFilterTextChange(e)}
                value={NameFilterValue}
            />

            {/* Search Results Grid */}
            <Box sx={gridStyle}>
                <DataGrid
                    rows={FilteredList}
                    columns={columns}
                    autoHeight
                    hideFooter={FilteredList.length === 0}
                    getRowId={(row) => row.ID}
                    pageSize={5}
                />
            </Box>

            {/* Status Banner */}
            <Typography variant="h6" style={{color: bannerColor}}>{StatusBanner}</Typography>

            {/* Participant Information Grid */}
            {ParticipantInformation.ID && (
                <>
                    <table>
                        <tr>
                            <td>Reg. Number</td>
                            <b>
                                <td>{getParticipantProperty("RunningNumber")}</td>
                            </b>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{getParticipantProperty("UserName")}</td>
                        </tr>
                        <tr>
                            <td style={{width: "300px"}}>WCA ID</td>
                            <td>{getParticipantProperty("WCAID")}</td>
                        </tr>
                        <tr>
                            <td>Region</td>
                            <td>{getParticipantProperty("country")}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{getParticipantProperty("gender")}</td>
                        </tr>
                        <tr>
                            <td>Birth Date</td>
                            <td>{getParticipantProperty("dob")}</td>
                        </tr>
                        <tr>
                            <td>Number Of Guest</td>
                            <td>{getParticipantProperty("GuestNumber")}</td>
                        </tr>
                        <tr>
                            <b>
                                <td>Check In Status</td>
                            </b>
                            <td><b>{getParticipantProperty("attended")}</b></td>
                        </tr>
                        <tr>
                            <td>Last Update Time</td>
                            <td>{getParticipantProperty("checkInDateTime")}</td>
                        </tr>

                        <tr>
                            <td>Outstanding?</td>

                                <td>   <b>{getParticipantProperty("Outstanding")}</b>
                                </td>
                        </tr>
                    </table>
                </>
            )}

            {/* Remark Field */}
            <Box sx={{mt: 2}}>
                <Typography variant="h6">Remark</Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={2}
                    value={Remark}
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Enter remark here"
                />
            </Box>

            <LoadingButton  size="large"
                            variant="contained"
                            style={{marginRight: "40px"}}
                    loading={updateStatusButtonLoading}
                    onClick={(e) => handleUpdateButtonClick(e, true)}>Check In</LoadingButton>
            <LoadingButton size="large"
                           variant="Outlined"
                           loading={updateStatusButtonLoading}
                           onClick={(e) => handleUpdateButtonClick(e, false)}>Cancel Check
                In</LoadingButton >
            {/* Staff Information Grid */}
            {ParticipantInformation.staffData && (
                <>
                    <Typography variant="h6">Staff Information</Typography>
                    <Box sx={gridStyle}>
                        <DataGrid
                            rows={[{...ParticipantInformation.staffData, id: ParticipantInformation.staffData.ID}]}
                            columns={staffColumns}
                            autoHeight
                            hideFooter
                            loading={staffGridLoading}
                        />
                    </Box>
                </>
            )}

            {/* MYCSA T-Shirt Information Grid */}
            {ParticipantInformation.mycsaTshirtImport === true && ParticipantInformation.mycsaTshirtImportData.length > 0 && (
                <>
                    <Typography variant="h6">Team Malaysia Jacket</Typography>
                    <Box sx={gridStyle}>
                        <DataGrid
                            rows={ParticipantInformation.mycsaTshirtImportData.map((item, index) => ({
                                ...item,
                                id: item.ID
                            }))}
                            columns={mycsaTshirtColumns}
                            autoHeight
                            hideFooter
                            loading={tshirtGridLoading}
                        />
                    </Box>
                </>
            )}

            {/* Merchant Information Grid */}
            {ParticipantInformation.merchantForm === true && ParticipantInformation.merchantData.length > 0 && (
                <>
                    <Typography variant="h6">Merchant Information</Typography>
                    <Box sx={gridStyle}>
                        <DataGrid
                            rows={ParticipantInformation.merchantData.map((item, index) => ({
                                ...item,
                                id: item.ID
                            }))}
                            columns={merchantColumns}
                            autoHeight
                            hideFooter
                            loading={merchantGridLoading}
                        />
                    </Box>
                </>
            )}


        </Box>
    );

    const NotAuthorizedPanel = (
        <Box sx={cardStyle}>
            <h1>Not Authorized</h1>
            <p>You are not authorized to check in participants</p>
            <p>Go to register page to switch account or login wca</p>
            <a href={`/competition/register/${id}`}>Register Page</a>
        </Box>
    )

    return (
        <div>
            {AuthorizedCheckIn ? CheckIn : NotAuthorizedPanel}
        </div>
    )
}

export default CheckInPage;