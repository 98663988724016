import Grid from "@mui/material/Grid";
import mycsa_logo_transparent from "./image/MYCSA LOGO_transparent.png";
import React from "react";
import Link from "@mui/material/Link";
import {createBreakpoint} from "react-use";
import {useLocation} from "react-router-dom";



const Footer = () =>{
    const location = useLocation();
    const isAC2024Path = location.pathname === '/RubiksWCAAsianChampionship2024/Home';
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();

    const isMobile = breakpoint === "tablet";
    return (
        <div style={{position:"relative",left:0,right:0,bottom:0}}>
        {isMobile?<MobileFooter isAC2024Path={isAC2024Path}/>:<DesktopFooter isAC2024Path={isAC2024Path}/>}
        </div>
    )

}
const DesktopFooter = (props) => {
    return (
        <footer style={{minWidth: "90vw"}}>
            <Grid container spacing={0} bottom={0} position={"relative"}
                  sx={{height: "200px", backgroundColor:props.isAC2024Path? "#1a182c":"#714EFF",marginLeft:"0px"}}>
                <Grid item xs={8}>
                    <img src={mycsa_logo_transparent} alt="MYCSA_Logo"
                         style={{
                             maxHeight: '80px',
                             width: 'auto',
                             marginLeft: '7%',
                             marginTop: "10px"
                         }}></img>
                </Grid>
                <Grid item xs={12}>
                    <hr width="90%" size="2" color="white" style={{paddingLeft: "5%", paddingRight: "5%"}}
                        noshade/>
                </Grid>

                <Grid item xs={8}>
                    <PolicyLink/>
                </Grid>
                <Grid item xs={4}>
                    <Copyright/>
                </Grid>

            </Grid>
        </footer>
    )
}

const MobileFooter = (props) => {
    return (
        <footer style={{marginBottom:0}}>
            <Grid container spacing={0} bottom={0} position={"relative"}
                  sx={{height: "200px", backgroundColor: props.isAC2024Path? "#1a182c":"#714EFF",marginLeft:"0px",marginBottom:0}}>
                <Grid item xs={8}>
                    <img src={mycsa_logo_transparent} alt="MYCSA_Logo"
                         style={{
                             maxHeight: '80px',
                             width: 'auto',
                             marginLeft: '7%',
                             marginTop: "10px"
                         }}></img>
                </Grid>
                <Grid item xs={12}>
                    <hr width="90%" size="2" color="white" style={{paddingLeft: "5%", paddingRight: "5%"}}
                        noshade/>
                </Grid>

                <Grid item xs={6}>
                    <PolicyLinkMobile/>
                </Grid>
                <Grid item xs={6}>
                    <Copyright/>
                </Grid>

            </Grid>
        </footer>
    )
}
const PolicyLink = () => {
    return (
        <div style={{
            pt: 4, position: "relative"
            , marginLeft: "30%"
            , bottom: 0
            , width: "100%"
        }}>
            <a href="/TermsAndConditions" style={{"paddingRight": "5%", color: "#FFFFFF"}}>Terms And Conditions</a>
            <span/>
            <a href="/RefundPolicy" style={{"paddingRight": "5%", color: "#FFFFFF"}}>Refund Policy</a>
            <span/>
            <a style={{"paddingRight": "5%", color: "#FFFFFF"}} href="/PrivacyPolicy">Privacy Policy</a>
        </div>
    )
}

const PolicyLinkMobile = () =>{
    return (
        <div style={{
             position: "relative"
            , bottom: 0
            , width: "100%",

        }}>
            <a href="/TermsAndConditions" style={{"paddingRight": "5%", color: "#FFFFFF"}}>Terms And Conditions</a>
            <br/>
            <a href="/RefundPolicy" style={{"paddingRight": "5%", color: "#FFFFFF"}}>Refund Policy</a>
            <br/>
            <a style={{"paddingRight": "5%", color: "#FFFFFF"}} href="/PrivacyPolicy">Privacy Policy</a>
        </div>
    )
}

function Copyright() {
    return (
        <div style={{display: "flex"}}>
            <p2 style={{color: "#FFFFFF"}}>
                {'Copyright © '}
                <Link sx={{color: "#FFFFFF"}} href="https://www.mycubeassociation.org/">
                    MYCSA
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </p2>


        </div>
    );
}
export default Footer;