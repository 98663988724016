import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/index';
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import AuthProvider from './services/WCAAuth/WCAAuthService';
import QueryParamPreservingRouter from './Component/routerLib/QueryParamPreservingRouter';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryParamPreservingRouter>
    <Provider store={store}>
    <AuthProvider>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </AuthProvider>
    </Provider>
  </QueryParamPreservingRouter>
);


