import React, {useEffect, useState} from 'react';
import {Button, CssBaseline, GlobalStyles} from "@mui/material";
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import MuiDrawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {Amplify, Auth} from 'aws-amplify';
import mycsa_logo from "./image/MYCSA_LOGO.png";
import { useLocation } from 'react-router-dom';
import "./NavBar.css";

import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
} from './NavbarElements';
import {createBreakpoint} from "react-use";
import {useSelector} from "react-redux";

const pages = ['Home', 'Events', 'About Us'];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
const drawerWidth = 1000;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
}));

const refreshPage = async () => {
    window.location.reload(false);
}
const signOut = async () => {
    try {
        await Auth.signOut();
        await refreshPage();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}




const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(2),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const Navbar = (props) => {
    const location = useLocation();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (event) => {
        setAnchorElNav(null);

    };
    const handleNavitemClick = async (event, value) => {
        setAnchorElNav(null);
        if (value === "Home") {
            window.location.href = "/home";
        }
        if (value === "Events") {
            window.location.href = "/events";
        }
        if (value === "Contact") {
            window.location.href = "/Contact";
        }
        if (value === "About Us") {
            window.location.href = "/aboutus";
        }
        if (value === "signIn") {
            window.location.href = "/login";
        }
        if (value === "memberProfile") {
            window.location.href = "/memberProfile";
        }
        if (value === "signOut") {
            await signOut()
        }

    };
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };


    const mdTheme = createTheme({
        palette: {
            mode: 'light',
            primary: {
                main: '#5e5757',
            },
            secondary: {
                main: '#f50057',
            },
            background: {
                default: '#ffffff',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.variant === 'contained' &&
                            ownerState.color === 'primary' && {
                                backgroundColor: '#4a52e5',
                                color: '#fff',
                                onSelected: {
                                    backgroundColor: '#3e47dc',
                                },
                                '&:hover': {
                                    background: "#4f90e7",
                                },
                            }),

                    }),
                },
            },
        },
    });
    const isLoggedIn = useSelector(state => state.sessionData.isLoggedIn);
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{
                display: 'flex',
                minWidth:"100%",
            }}>
                <CssBaseline/>
                <AppBar position="relative" open={open} style={{opacity: 0.9, boxShadow: 'none',backgroundColor:"#fff"}}>
                    <Toolbar
                        sx={{
                            pr: '1px', // keep right padding when drawer closed
                        }}
                    >
                        <img src={mycsa_logo} alt="Group Photo" className='compImageMobile' style={{
                            maxHeight: '80px',
                            width: 'auto',
                            marginLeft: '0px'
                        }}></img>
                        {pages.map((page) => (
                            <MenuItem sx={{display: {xs: 'none', md: 'flex'}}} key={page}
                                      onClick={(event) => handleNavitemClick(event, page)}>
                                <Typography sx={{fontSize: 20, 'font-family': 'Arial', color: 'black'}}
                                            textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="primary"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                        {isLoggedIn == true && <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="primary"
                            >
                                <Avatar/>
                            </IconButton>
                        </Box>}
                        <Menu

                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}

                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            style={{width: '5000px'}}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {isLoggedIn == true &&
                                <MenuItem sx={{justifyContent: 'center'}} className={'menuItem'} onClick={(event) => handleNavitemClick(event, "memberProfile")}>
                                    <Avatar/> Profile
                                </MenuItem>}
                            {isLoggedIn == false && <MenuItem className={'menuItem'} sx={{display: {xs: 'flex', md: 'none',justifyContent: 'center'}}}
                                                              onClick={(event) => handleNavitemClick(event, "signIn")}>
                                <Avatar/> MYCSA User Sign In
                            </MenuItem>}
                            {isLoggedIn == true && <MenuItem className={'menuItem'} sx={{display: {xs: 'flex', md: 'flex',justifyContent: 'center',height:"60px"}}}
                                                             onClick={(event) => handleNavitemClick(event, "signOut")}>
                                Sign Out
                            </MenuItem>}
                            <Divider sx={{display: {xs: 'flex', md: 'none'}}}/>
                            {pages.map((page) => (
                                <MenuItem className={'menuItem'} sx={{display: {xs: 'flex', md: 'none',justifyContent: 'center',height:"60px"}}} key={page} key={page}
                                          onClick={(event) => handleNavitemClick(event, page)}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu
>

                        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                            {isLoggedIn == false && <NavBtnLink to='/login'>Log In</NavBtnLink>}
                        </Box>
                    </Toolbar>
                </AppBar>

            </Box>
        </ThemeProvider>
    );

};

export default Navbar;