import React, { useRef, useEffect, useState } from 'react';
import './Homepage.css';
import rubikImage from '../image/rubik_main_logo.png';
import wcaLogo from '../image/WCAlogo.png';
import mycaLogo from '../image/MYCSA_LOGO.png';
import {createBreakpoint} from "react-use";
import venueHallImage from "../image/VenueHall.jpg"
import Button from '@mui/material/Button';

const AsianChampHomePage = () => {
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";

    const pjSectionRef = useRef(null);
    const [isPjSectionVisible,setIsPjSectionVisible] = useState(null);

    const infoSectionRef = useRef(null);
    const [isInfoVisible, setIsInfoVisible] = useState(false);

    const infoSectionRef2 = useRef(null);
    const [isInfoVisible2, setIsInfoVisible2] = useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.target === infoSectionRef.current) {
                        if (entry.isIntersecting) {
                            setIsInfoVisible(true);
                        }
                    }
                    else if (entry.target === infoSectionRef2.current) {
                        if (entry.isIntersecting) {
                            setIsInfoVisible2(true);
                        }
                    }
                    else if (entry.target === pjSectionRef.current) {
                        if (entry.isIntersecting) {
                            setIsPjSectionVisible(true);
                        }
                    }
                });
            },
            { threshold: 0.3}
        );


        if (infoSectionRef.current) {
            observer.observe(infoSectionRef.current);
        }
        if (infoSectionRef2.current) {
            observer.observe(infoSectionRef2.current);
        }
        if (pjSectionRef.current) {
            observer.observe(pjSectionRef.current);
        }

        return () => {
            if (infoSectionRef.current) {
                observer.unobserve(infoSectionRef.current);
            }
            if (infoSectionRef2.current) {
                observer.unobserve(infoSectionRef2.current);
            }
            if (pjSectionRef.current) {
                observer.unobserve(pjSectionRef.current);
            }
        };
    }, []);



    return (
        <div >
            <div className="parallax-container">
            </div>
            <div className="content-container" style={{marginBottom:"10%"}}>
                <div className="content-section">
                    <h1>Welcome to the Official Website of Rubik's Asian Championship 2024!</h1>
                    <h3>
                        We are thrilled to announce that the Rubik's Asian Championship 2024 will be held in Malaysia, organized
                        by Malaysia Cube Sports Association (MYCSA). We are happy to welcome you with our Malaysian hospitality!
                    </h3>

                </div>
            </div>
            <div className="slide-container">
                 <div ref={pjSectionRef} className={isMobile?`slider-mobile ${isPjSectionVisible ? 'visible' : ''}`:"slide-content"}>
                    <h2>At The Heart of Malaysia</h2>
                    <h1>Putrajaya</h1>
                </div>
            </div>
            <div className="spacer"></div>
            <div ref={infoSectionRef} className={`info-section ${isInfoVisible ? 'visible' : ''}`}>
                <h3>Location</h3>
                <p>Putrajaya, Malaysia</p>
                <h3>Venue</h3>
                <a style={{color: "black"}} target="_blank"
                   href="https://www.ioigrand.com.my">IOI Grand Exhibition & Convention Centre</a>
                <div className="sponsor-images">
                    <img src={venueHallImage} alt="Venue Hall"/>
                </div>
                <h3>Address</h3>
                <a style={{color: "black"}} target="_blank"
                   href="https://www.google.com/maps/place/2.970663,101.713668">2AT-3A, Level 3, IOI City Mall, Lbh IRC,
                    IOI Resort, 62502 Putrajaya</a>
                <h3>Date (GMT +8)</h3>
                <p>November 1st to 4th, 2024</p>
                <h3>Registration Open Period (GMT +8)</h3>
                <p>May 31, 2024 to Sept 2, 2024</p>
                <h3>Participants Limit</h3>
                <p>800</p>
                <h3>WCA Website</h3>
                <a style={{color: "black"}} target="_blank"
                   href="https://www.worldcubeassociation.org/competitions/RubiksWCAAsianChampionship2024">Link</a>
                <h3>Registration Website</h3>
                <a style={{color: "black"}} target="_blank"
                   href="https://www.mycubeassociation.org/competition/register/RubiksWCAAsianChampionship2024">Link</a>
            </div>
            <div className="spacer"></div>
            <div ref={infoSectionRef2} className={`info-section ${isInfoVisible2 ? 'visible' : ''}`}>
                <h2>Organizer</h2>
                <div className="sponsor-images">
                    <img src={mycaLogo} alt="mycsa"/>
                </div>
                <h2>Sponsors and Collaborators</h2>
                <div className="sponsor-images">
                    <img src={wcaLogo} alt="Sponsor 1"/>
                    <img src={rubikImage} alt="Sponsor 2"/>
                </div>
                <h2>
                    Interested?
                </h2>
                <Button onClick={()=>{window.location.href = '/competition/register/RubiksWCAAsianChampionship2024'}} variant="outlined">Register Here</Button>

            </div>
        </div>
    );
};

export default AsianChampHomePage;