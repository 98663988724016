import React, { useState,useEffect } from "react";
import { useParams,useNavigate } from "react-router-dom";
const RefundPolicyPage = () =>{

    return (
        <div style={{"margin-left": "10%"}}>
            <h1 >
                Refund Policy
            </h1>
            <h1>Return and Refund Policy</h1>
<p>Last updated: March 16, 2023</p>
<p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns.</p>
<p>The following terms are applicable for any payment that You purchased with Us.</p>
<h1>Interpretation and Definitions</h1>
<h2>Interpretation</h2>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h2>Definitions</h2>
<p>For the purposes of this Return and Refund Policy:</p>
<ul>
<li>
<p><strong>Association</strong> (referred to as either &quot;the Association&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Malaysia Cube Association</p>
</li>
<li>
<p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
</li>
<li>
<p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
</li>
<li>
<p><strong>Last Cancellation Date</strong> refers to the deadline for cancellation of participant in a WCA competition to get partial or full refund.</p>
</li>
<li>
<p><strong>Official Competition</strong> refers to the competition recognize by World Cube Association</p>
</li>
<li>
<p><strong>Service</strong> refers to the Website.</p>
</li>
<li>
<p><strong>Website</strong> refers to Malaysia Cube Association Website, accessible from <a href="www.mycubeassociation.org" rel="external nofollow noopener" target="_blank">www.mycubeassociation.org</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<h1>Your Order Cancellation Rights</h1>
<h2>Competition Participating Fee Payment</h2>
<p>You are entitled to cancel Your registration in an official competition.</p>
<p>The deadline for cancelling your registration after being approved for participating in an official competition and get partial or full refund is stated as Last Cancellation Date in the competition website.</p>
<p>You are eligible for full refund if you're not approved or declined to partipate in the official competition.</p>
<p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by emailing the organizer email stated in the official competition webpage or emailing:</p>
<ul>
<li>By email: info@mycubeassociation.com</li>
</ul>
<p>We will inform you the outcome and refund amount through your email within 7 working days.</p>
<p>We will reimburse You no later than 90 days from the approval date of refund. We will use the same means of payment as You used for the Order, this might incur payement gateway fees for such reimbursement.</p>
<h2>Contact Us</h2>
<p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
<ul>
<li>By email: info@mycubeassociation.com</li>
</ul>
        </div>
    );
}

export default RefundPolicyPage;