// create a react component that display payment pending indication, and a button click to refrech the current page

// Path: src/Component/PaymentPending/PaymentPendingPage.js
import {React,useState,useEffect} from 'react';
import {Button} from "@mui/material";
//only appear the refresh button after 5 seconds

const PaymentPendingPage = (props) => {
    //initiate a timer
    const [timeLeft, setTimeLeft] = useState(600);
    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShowButton(true);
        }, 9000);
    }, [showButton]);

    //count time
    useEffect(() => {
        if (!timeLeft) {
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeLeft]);
    const handleReload = () => {
        props.displayRazorpay(true,timeLeft);
        setShowButton(false);
    }
    return (
        <div>
            <h1>Please wait for the payment windows to appear......</h1>
            <p>Please don't close or refresh the payment popup window if didn't intended to.</p>
            {showButton &&
                <div><small> Click the reload button below to
                    reopen payment
                    window: </small>
                    <Button variant="alert" style={{backgroundColor: "#E28B78"}}
                            onClick={(timeLeft) => handleReload(timeLeft)}>Reload</Button></div>
            }
        </div>
    )
}
export {PaymentPendingPage};