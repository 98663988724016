import React from 'react';
import mycaOrganizationImage from "./image/OrganizationChart.jpg";
import mccGroupPhoto from "./image/mccGroupPhoto.jpg";
import MYCA_Logo from "./image/MYCA_LOGO.jpg";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {createBreakpoint} from "react-use";
const AboutUsWeb = () => {
    return (
        <div style={{"margin-left": "4%"}}>
            <h1>About Us</h1>
            <h2>Malaysia Cube Sports Association</h2>
            <p>
                Malaysia Cube Sports Association (MYCSA) acts as the regional organisation body of the World Cube Association
                (WCA) for the sport of speedcubing. We also registered as non-profit organization in Malaysia.
            </p>
            <h2>What We do</h2>
            <p>
                We were founded to support and promote the sport of speedcubing in Malaysia by organising a variety of
                activities such as WCA-sanctioned competitions (i.e. official WCA competitions), non-WCA-sanctioned
                competitions (i.e. unofficial competitions), speedcubing gatherings, roadshows, workshops and trainings.
            </p>
            <img style={{width: "90%"}} src={mccGroupPhoto} alt="Competition Group Photo"></img>
            <h2>Our Goal</h2>
            <p>
                We are committed to establishing a platform that empowers all Malaysian cubers to enhance their
                speedcubing skills and building up the community. Additionally, we offer support and resources to those
                interested in organizing competitions in Malaysia.
            </p>
            <h2>Organization Chart</h2>
            <p>
                We are an officially recognized organization in Malaysia, conducting an annual general meeting to elect
                our board members each year.
            </p>
            <img style={{width: "100%"}} src={mycaOrganizationImage} alt="MYCSA ORGANIZATION CHART"></img>

        </div>
    )
}
const AboutUsMobile = () => {
    return (
        <div style={{"margin-left": "2%"}}>
            <h1>About Us</h1>
            <h2>Malaysia Cube Sports Association</h2>
            <p>
                Malaysia Cube Sports Association (MYCSA) acts as the regional organisation body of the World Cube
                Association
                (WCA) for the sport of speedcubing. We also registered as non-profit organization in Malaysia.
            </p>
            <h2>What We do</h2>
            <p>
                We were founded to support and promote the sport of speedcubing in Malaysia by organising a variety of
                activities such as WCA-sanctioned competitions (i.e. official WCA competitions), non-WCA-sanctioned
                competitions (i.e. unofficial competitions), speedcubing gatherings, roadshows, workshops and trainings.
            </p>
            <img style={{width: "90%"}} src={mccGroupPhoto} alt="Competition Group Photo"></img>
            <h2>Our Goal</h2>
            <p>
                We are committed to establishing a platform that empowers all Malaysian cubers to enhance their
                speedcubing skills and building up the community. Additionally, we offer support and resources to those
                interested in organizing competitions in Malaysia.
            </p>
            <h2>Organization Chart</h2>
            <p>
                We are an officially recognized organization in Malaysia, conducting an annual general meeting to elect
                our board members each year.
            </p>
            <img style={{width: "100%"}} src={mycaOrganizationImage} alt="MYCSA ORGANIZATION CHART"></img>

        </div>
    )
}
const AboutUsPage = () => {
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
    return (
        <div>
            {isMobile ? <AboutUsMobile/> : <AboutUsWeb/>}
        </div>
    )

};

export default AboutUsPage;
