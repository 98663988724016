import axios from "axios";
import config from "../../../config.json";



    const getWcaPerson = async (wcaId)=> {
        try {
            const response = await axios.get(`${config.WCA_URL}/api/v0/persons/${wcaId}`);
            return response.data;
        } catch (error) {return error}
    };

    export {getWcaPerson};