import {useEffect, useState} from "react";
import * as React from 'react';
import {getUserinfo} from "../../../services/AWS/user/user";
import {useDispatch, useSelector} from "react-redux";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Profile_background from "./image/Profile_background.jpeg";
import MYCA_LOGO from "./image/MYCA_LOGO.jpg";
import './MemberPortal.css';
import moment from "moment/moment";
import {createBreakpoint} from "react-use";
import {sessionDataActions} from "../../../store";


export const MemberPortal = (props) => {
    const dispatch = useDispatch();
    const authToken = useSelector(state => state.sessionData.authToken);
    const isLoggedIn = useSelector(state => state.sessionData.isLoggedIn);
    const [userInfo,setUserInfo] = useState(null);
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
    const styles = {
        frame:{
            marginLeft: isMobile ? "0%" : "30%",
            minWidth:isMobile ?"60vw":"60vw",
        }
    };
    const handleSubscriptionClick = () => {
        window.location.href = "/subscribeMember";
    }
    const setUserInformation  = async (authToken) => {
        dispatch(sessionDataActions.loading());
        const userInfo =await  getUserinfo(authToken)
        setUserInfo(userInfo);
        dispatch(sessionDataActions.loaded());
    }
    useEffect(() => {
if (isLoggedIn && authToken){
    setUserInformation(authToken);
}
        },[authToken]
    )

    const  MemberInfoCard = () => {
        return(
            <Card  variant="elevation" sx={{minWidth:"360px",maxWidth:"400px",margin:"2px",marginLeft:"auto",marginRight:"auto"}}>
                <CardMedia
                    sx={{ height: 140 }}
                    image={Profile_background}
                    title="Personal_Info_background"
                />
                <CardContent>
                    <Typography variant="h5" component="div">
                        Personal Information
                    </Typography>
                    <table  style={{width:"100%"}}>
                        <tr>
                            <td className= "InfoHeader"><b>Full Name</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.Name} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Date of Birth</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.birthdate} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Country</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.country} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>State</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.state} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Identity No.</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.identityNo} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Email</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.email} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Linked WCA Email</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.WCAemail} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>PhoneNumber</b></td>
                            <td className="InfoContent">{userInfo&& userInfo.phoneNumber} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Date Joined</b></td>
                            <td className="InfoContent">{userInfo&& moment(userInfo.createdOn).format("DD-MM-YYYY")} </td>
                        </tr>
                    </table>
                </CardContent>

            </Card>
        )
    }

    const PremiumMemberCard = () => {
        return(
            <Card  variant="elevation" sx={{minWidth:"360px",maxWidth:"400px",margin:"2px",marginLeft:"auto",marginRight:"auto"}}>
                <CardMedia
                    sx={{ height:125, border: "2px #00000099 solid" }}
                    image={MYCA_LOGO}
                    title="Membership_Background"
                />
                <CardContent>
                    <Typography variant="h5" component="div">
                        MYCSA Membership Information
                    </Typography>
                    <table  style={{width:"100%"}}>
                        <tr>
                            <td className= "InfoHeader"><b>Membership Tier</b></td>
                            <td className="InfoContent">{userInfo &&userInfo.hasOwnProperty("tier")? userInfo.tier:"Normal Member"} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Last Premium Renew</b></td>
                            <td className="InfoContent">{userInfo && userInfo.hasOwnProperty("lastPremiumRenewDate")? moment.unix(userInfo.lastPremiumRenewDate).format("DD-MM-YYYY"):"-"} </td>
                        </tr>
                        <tr>
                            <td className= "InfoHeader"><b>Premium Due Date</b></td>
                            <td className="InfoContent">{userInfo &&userInfo.hasOwnProperty("PremiumMemberDueDate")? moment.unix(userInfo.PremiumMemberDueDate).format("DD-MM-YYYY"):"-"} </td>
                        </tr>
                    </table>
                </CardContent>
                <CardActions>
                    <Button color="secondary" onClick={handleSubscriptionClick} size="large">Renew & Purchase Premium Member</Button>
                </CardActions>

            </Card>
        )
    }

    return (
        <div style={styles.frame}>
            <h1>Member Portal</h1>
            <Box sx={{ flexGrow: 1 }}>
            <Grid sx={{backgroundColor:"#FFFFFF"}}  container spacing={1}>
                <Grid item xs={12} md={6}>
                <MemberInfoCard/>
                </Grid>
                <Grid item xs={12} md={6}>
                <PremiumMemberCard/>
                    </Grid>
            </Grid>
            </Box>

        </div>
    )
}