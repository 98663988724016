import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./Register.css";
import {countries} from "../../utils/country";
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import {userRegisterService} from "../../services/AWS/user/user";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import dayjs from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment/moment";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from "@mui/material/Alert";
import {sessionDataActions} from "../../store";
function UserRegisterPage() {
  // React States
    const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputs, setInputs] = useState({})
  const [APIStatus,setAPIStatus] = useState(true);
  const [APIMessage,setAPIMessage] = useState(true);
  const [SelectedDate,setSelectedDate] = useState();
  const [consentCheck,setConsentCheck] = useState(false);
  const[country, setCountry] = useState("60");



  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };
const validateEmail = (input) => {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return validRegex.test(String(input).toLowerCase())
}
  const handleFormChange = (event,name) => {

    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleConsentCheck = (event) => {
    setConsentCheck(event.target.checked);
  }

const handleCountryChange = (event,value) =>{
setCountry(value.phone);
}
  const handleSubmit = async (event) => {

    //Prevent page reload
    event.preventDefault();
    setErrorMessages({name:"none",message:"none"});
    var { uname, pass } = document.forms[0];

    //validate input
    if (inputs.FullName === undefined||!inputs.FullName|| inputs.FullName.length == 0){
      setErrorMessages({name:"FullName",message:"Name Cannot be Blank !"})

      return;
    }
    if (!inputs.Phone  || inputs.Phone.trim().length == 0){
      setErrorMessages({name:"Phone",message:"Phone Number Cannot be Blank !"})
      return;
    }
    if (!inputs.gender ||inputs.gender =="-"){
      setErrorMessages({name:"gender",message:"gender Cannot be Blank !"})
      return;
    }
      if (!inputs.state ||inputs.state =="-"){
          setErrorMessages({name:"state",message:"State Cannot be Blank !"})
          return;
      }

      if (!inputs.identityNo||inputs.identityNo.trim().length == 0){
            setErrorMessages({name:"identityNo",message:"Identity No Cannot be Blank !"})
            return;
      }
      if (!SelectedDate){
          setErrorMessages({name:"SelectedDate",message:"Birth Date  Cannot be Blank !"})
          return;
      }
    if(!validateEmail(inputs.email)){
      setErrorMessages({name:"EmailError",message:"Invalid Email !"})
      return;
    }
      if (!inputs.pass||inputs.pass.trim().length == 0){
          setErrorMessages({name:"pass",message:"password  Cannot be Blank !"})
          return;
      }
    if (inputs.pass !== inputs.passConfirm)
    {
      setErrorMessages({name:"confirmPW",message:"Password is not the same !"})

      return;
    }
    if (!country){
        setErrorMessages({name:"Phone",message:"Phone Country Cannot be Blank !"})
        return;
    }
      let phone = inputs.Phone;
      //remove 0
      if (phone[0] == "0"){
          phone = phone.substring(1);
      }
      //remove phone country code if start with country code
      if (phone.substring(0,country.length) == country){
          phone = phone.substring(country.length);

      }
      if (consentCheck !== true){
            setErrorMessages({name:"consentCheck",message:"Please Check the Consent Box"})
            return;
      }

      dispatch(sessionDataActions.loading());;
    const RegisterRespond =  await userRegisterService({
      username: inputs.email,
      password:inputs.pass,
      name:inputs.FullName,
      phone: `+${country}${phone}`,
      wcaID: inputs.wcaid,
      country:'Malaysia',
      birthdate:SelectedDate.format("YYYY-MM-DD"),
      gender:inputs.gender,
      state:inputs.state,
        identityNo:inputs.identityNo.toString().replace("-",""),

    })
      dispatch(sessionDataActions.loaded());;

    if (RegisterRespond.message == "success"){
      setIsSubmitted(true);
    }
    else{
      setErrorMessages({name:"APIError",message:RegisterRespond.message});
      setIsSubmitted(false);
        dispatch(sessionDataActions.loaded());;
    }
      dispatch(sessionDataActions.loaded());;
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}
      </div>
    );

  // JSX code for login form
  const renderForm = (
   
    <div >
        <p2 >*Membership Registration only open for Nationality of Malaysia</p2>
      <form onSubmit={ handleSubmit }>
      <div className="input-container">

          <TextField  multiline name="FullName" label="Full Name*" type="text"   onChange={(event) =>handleFormChange(event,"FullName")} />
          {renderErrorMessage("FullName")}
        </div>
          <div className="input-container">
              <TextField multiline defaultValue={"Malaysia"} name="nationality*" label="Nationality" variant="outlined" disabled={true} />
              {renderErrorMessage("Nationality")}
          </div>

        <div className="input-container">
        <FormControl fullWidth>
            <InputLabel id="state-input-label">State</InputLabel>
            <Select   name="state" labelId="state-input-label" value={inputs.state} onChange={(event) =>handleFormChange(event,"state")} label="state">
                <option value= {null}>-</option>
                <MenuItem value="Perlis">Perlis</MenuItem>
                <MenuItem value="Kedah">Kedah</MenuItem>
                <MenuItem value="Penang">Penang</MenuItem>
                <MenuItem value="Perak">Perak</MenuItem>
                <MenuItem value="Selangor">Selangor</MenuItem>
                <MenuItem value="Negeri Sembilan">Negeri Sembilan</MenuItem>
                <MenuItem value="Melaka">Melaka</MenuItem>
                <MenuItem value="Johor">Johor</MenuItem>
                <MenuItem value="Kelantan">Kelantan</MenuItem>
                <MenuItem value="Terengganu">Terengganu</MenuItem>
                <MenuItem value="Sabah ">Sabah</MenuItem>
                <MenuItem value="Sarawak">Sarawak</MenuItem>
                <MenuItem value="Kuala Lumpur">Kuala Lumpur</MenuItem>
                <MenuItem value="Putrajaya">Putrajaya</MenuItem>
                <MenuItem value="Labuan ">Labuan </MenuItem>
            </Select>
            {renderErrorMessage("state")}
        </FormControl>
            <TextField name="IcNo" label="Malaysia Identity Card No*"  type="number"  variant="outlined" onChange={(event) =>handleFormChange(event,"identityNo")} />
            {renderErrorMessage("identityNo")}
            <FormControl fullWidth>

            <InputLabel id="gender-input-label">gender</InputLabel>
        <Select   name="Gender" labelId="gender-input-label" value={inputs.gender} onChange={(event) =>handleFormChange(event,"gender")} label="gender">
        <option value= {null}>-</option>
<MenuItem value="Male">Male</MenuItem>

<MenuItem value="Female">Female</MenuItem>

<MenuItem value="Other">Other</MenuItem>
</Select>
            </FormControl>
{renderErrorMessage("gender")}
        </div>
        <div className="input-container">
            <TextField multiline labelId="email-input-label"  name="email" label="Email*" variant="outlined" onChange={(event) =>handleFormChange(event,"email")} />
          {renderErrorMessage("EmailError")}
        </div>
          <InputLabel  className="input-container" id="phone-input-label">Phone Number</InputLabel>
          <div className="input-container">
              <Autocomplete
                  id="country-select-demo"
                  sx={{ width: 200 }}
                  options={countries}
                  autoHighlight
                  defaultValue={countries[0]}
                  onChange={handleCountryChange}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                          />
                          {option.label} ({option.code}) +{option.phone}
                      </Box>
                  )}
                  renderInput={(params) => (
                      <TextField
                          multiline
                          {...params}
                          label="Phone Country*"
                          inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                      />
                  )}
              />
          </div>
        <div className="input-container">
            <TextField name="Phone" label="Phone Number*"  type="number"  variant="outlined" onChange={(event) =>handleFormChange(event,"Phone")} />
          {renderErrorMessage("Phone")}
        </div>
        <div className="input-container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={SelectedDate}
                    label="Date of Birth*"
                    disableFuture={true}
                    onChange={(newValue) => setSelectedDate(newValue)}
                />

                {renderErrorMessage("SelectedDate")}
            </LocalizationProvider>
        </div>
        <div className="input-container">
            <TextField  name="pass" label="Password*" type="password"  autoComplete="current-password" variant="outlined" onChange={(event) =>handleFormChange(event,"pass")} />
          {renderErrorMessage("pass")}
            <Alert severity="info">Must Contain one special character, one uppercase, one lowercase, at least 8 characters</Alert>
        </div>
        <div className="input-container">
            <TextField  name="passConfirm" label="Confirm Password*" type="password"  autoComplete="current-password" variant="outlined" onChange={(event) =>handleFormChange(event,"passConfirm")} />
          {renderErrorMessage("confirmPW")}
        </div>
        <div className="input-container">
            <TextField multiline name="wcaid" label="WCA ID (If have)" type="text"  autoComplete="current-password" variant="outlined" onChange={(event) =>handleFormChange(event,"wcaid")} />
          {renderErrorMessage("wcaid")}
        </div>
        <div>{renderErrorMessage("APIError")}</div>
          <FormControlLabel onChange={handleConsentCheck} control={<Checkbox />} label="I hereby declare that the information provided above is true and correct. I also understand that any willful
dishonesty may cause termination of account and agree to the Terms and Conditions of MYCSA. " />
          {renderErrorMessage("consentCheck")}
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (

    <div className="app">
      <div className="register-form">
        <div className="title">MYCSA Member Registration</div>
        {isSubmitted ? <div>Registration Successful! <a href={`/VerifyEmail/${inputs.email}`}rel="noreferrer">Click here
  </a> to Verify your Account</div> : renderForm}
      </div>
    </div>

  );
}


export default UserRegisterPage;