import React from 'react';
import groupImage from "./image/MCC Cover photo.jpg";
import bannerPhoto from "./image/MYCABannerPhoto.jpg"
import compBackDrop from "./image/competition_backdrop.jpg"
import  "./HomeStyle.css"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createBreakpoint } from "react-use";
import mainImage from "./image/mainImage.png";
import mainImage_mobile from "./image/mainImage_mobile.png";
import { Typography } from '@mui/material';
const HomePage = () =>{
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
      return(
        <div style={{width:"100%"}}>
            {isMobile? <MobileMainHomePage/>:<MainHomePage/>}
        </div>)

}
const MainHomePage = () =>{
    return (
        <Box sx={{ flexGrow: 1,minWidth:"92vw"}}>
     <div id = 'mycaMainWord'>
<Typography sx={{'margin-top':'10vh',textAlign:'center',fontFamily:'sans-serif',variant:"h2", fontWeight: 700,fontSize:50,maxWidth:'600px',marginLeft:'auto',marginRight:'auto',color:'#714EFF','line-height': '1'}}>
    Malaysia Cube Sports Association
</Typography>
     </div>
            <div>
                <Typography sx={{variant:'body1',fontFamily:'sans-serif',marginTop:'30px', marginLeft:'auto',marginRight:'auto', fontWeight: 400,textAlign:'center',maxWidth:'600px'}}>
                    Malaysia Cube Sport Association (MYCSA) acts as the regional organisation body of the World Cube Association (WCA) for the sport of speedcubing. we host and promote cubing events which are supported by WCA and Malaysia Cube Club to passionate speedcubers across Malaysia.
                </Typography>
            </div>
            <div>
                <img style={{width: "80%",marginLeft:"6%",marginRight:"6%"}} src={mainImage} alt="MYCA ORGANIZATION CHART"></img>
            </div>
        </Box>
    )
}
const MobileMainHomePage = () =>{
    return (
        <Box sx={{ flexGrow: 1,minWidth:"92vw"}}>
            <div id = 'mycaMainWord'>
                <Typography sx={{'margin-top':'10vh',textAlign:'center',fontFamily:'sans-serif',variant:"h2", fontWeight: 700,fontSize:50,maxWidth:'600px',marginLeft:'auto',marginRight:'auto',color:'#714EFF','line-height': '1'}}>
                    Malaysia Cube Sports Association
                </Typography>
            </div>
            <div>
                <Typography sx={{variant:'body1',fontFamily:'sans-serif',marginTop:'30px', marginLeft:'auto',marginRight:'auto', fontWeight: 400,textAlign:'center',maxWidth:'600px'}}>
                    Malaysia Cube Sport Association (MYCSA) acts as the regional organisation body of the World Cube Association (WCA) for the sport of speedcubing. we host and promote cubing events which are supported by WCA and Malaysia Cube Club to passionate speedcubers across Malaysia.
                </Typography>
            </div>
            <div>
                <img style={{width: "80%",marginLeft:"6%",marginRight:"6%"}} src={mainImage_mobile} alt="MYCA ORGANIZATION CHART"></img>
            </div>
        </Box>
    )
}
export default HomePage;