import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import {GetMYCSAEventCode, GetWCAEventCode} from "../../utils/constant";
import moment from "moment/moment";
const getPreCompRanking = async (competitionName,event)=> {
    try {

        const url = `https://www.worldcubeassociation.org/api/v0/competitions/${competitionName}/psych-sheet/${GetWCAEventCode[event]}`;
          const response = await axios.get(url);
        const result = response.data.sorted_rankings;
        const transformedResult =await Promise.all( result.map((item) => {
            return {
                ID : uuidv4(),
                name: item.name,
                wcaId: item.wca_id,
                average: convertResult(item.average_best,event,  true),
                single: convertResult(item.single_best,event)
            }
        }));
return transformedResult;

    } catch (error) {
        console.log(error)
        return error
    }
};

const convertResult = (result,event,isAverage = false) => {
    if (result === undefined || result === null || result === 0) {
        return "-"
    }
    if (event === "3x3x3 Multi-Blind") {
        result = 99 - Math.floor(result/10000000);
    }
    else if (event === "3x3x3 Fewest Moves" && !isAverage) {
        return result;
    }
    else if (event === "3x3x3 Fewest Moves" && isAverage) {
        return result/100;
    }
    else {
        const secondsResult = result / 100;
        result = moment.utc(secondsResult * 1000).format('mm:ss.SS');

    }
    return result;
};

export {getPreCompRanking};