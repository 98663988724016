
import {configureStore, createSlice} from '@reduxjs/toolkit';


const initialState = {
    isLoading: false,
    isLoggedIn: true,
    authToken: null,
    isWCALoggedIn: false,
    WCAuserLoggedInCheck: false,
    WCAAuthloading:false,
    userInformation:null
}

const sessionDateSlice  = createSlice({
    name: 'sessionData',
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true
        }
        ,
        loaded: (state) => {
            state.isLoading = false
        },
        WCAAuthLoading:(state) =>{
            state.WCAAuthloading=true
        },
        WCAAuthLoaded:(state) =>{
            state.WCAAuthloading = false
            state.WCAuserLoggedInCheck = true
        }
        ,
        loggedIn: (state, action) => {
            state.isLoggedIn = true
            state.authToken = action.payload
        }
        ,
        logOut: (state) => {
            state.isLoggedIn = false
            state.authToken = null
        }
        ,
        WCALoggedIn: (state) => {
            state.isWCALoggedIn = true
            state.WCAuserLoggedInCheck = true
        }
        ,
        WCALoggedOut: (state) => {
            state.isWCALoggedIn = false
            state.WCAuserLoggedInCheck = false
        }
        ,
        WCAuserLoggedInCheck: (state) => {
            state.WCAuserLoggedInCheck = true
        },
        SetUserInformation: (state, action) => {
            state.userInformation = action.payload
        }


    }
})

const store = configureStore({
    reducer: {sessionData:sessionDateSlice.reducer}
})
export const sessionDataActions = sessionDateSlice.actions;

export default store;