//page to display when payment is timed out
//
const TimeoutPage = () => {
    return (
        <div>
            <h1>Payment Timeout</h1>
            <p>Payment timeout, please retry the process from the start</p>
        </div>
    )
}
export default TimeoutPage;