import React, {useEffect} from "react";
import "./AuthCheck.css";
import LoginPage from '../../Pages/login/loginPage';
import {useDispatch, useSelector} from "react-redux";

const AuthCheckPage = (props) =>{
    const isLoggedIn = useSelector(state => state.sessionData.isLoggedIn);
const RenderLoginPage = () => {
    return (
    <div className='Main'>
        <h1>Please Login to Proceed</h1>
        <LoginPage />
    </div>
         );}
if (!isLoggedIn){
        return(
            <div>
  <RenderLoginPage/>
  </div>
        )
    }
return (
    <div>{props.children}</div>



    
)


}

export default AuthCheckPage;