import './Contact.css';
import {createBreakpoint} from "react-use";
import React from "react";
const ContactUsWeb = () =>{
    return (
        <div style={{"margin-left": "40%", minWidth:"70vh"}}>
            <h1 style={{"margin-btm": "5%"}}>Contact Information</h1>
            <h2 style={{"margin-btm": "5%"}}>You may contact us with the following channel:</h2>
            <table style={{"margin-bottom": "30%"}}>
                <tr>
                    <td  className="InfoHeader"><b>Email</b></td>
                    <td className="InfoContent">info@mycubeassociation.com</td>
                </tr>
                <tr>
                    <td  className="InfoHeader"><b>Facebook Page</b></td>
                    <td className="InfoContent"><a href="https://www.facebook.com/mycubeassociation" target="_blank">www.facebook.com/mycubeassociation</a></td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Instagram</b></td>
                    <td className="InfoContent"><a href="https://www.instagram.com/mycubeassociation/" target="_blank">mycubeassociation Official Instagram</a></td>
                </tr>
            </table>
        </div>
    )
}

const ContactUsMobile = () =>{
    return (
        <div style={{"margin-left": "10%"}}>
            <h1 style={{"margin-btm": "5%"}}>Contact Information</h1>
            <h2 style={{"margin-btm": "5%"}}>You may contact us with the following channel:</h2>
            <table style={{"margin-bottom": "30%"}}>
                <tr>
                    <td  className="InfoHeader"><b>Email</b></td>
                </tr>
                <tr>
                    <td className="InfoContent">info@mycubeassociation.com</td>
                </tr>
                <tr>
                    <td  className="InfoHeader"><b>Facebook Page</b></td>
                </tr>
                <tr>
                    <td className="InfoContent"><a href="https://www.facebook.com/mycubeassociation" target="_blank">www.facebook.com/mycubeassociation</a></td>
                </tr>
                <tr>
                    <td className="InfoHeader"><b>Instagram</b></td>
                </tr>
                <tr>
                    <td className="InfoContent"><a href="https://www.instagram.com/mycubeassociation/" target="_blank">mycubeassociation Official Instagram</a></td>
                </tr>
            </table>
        </div>
    )
}
const ContactPage = () =>{
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
    return (
<div>
        {isMobile ? <ContactUsMobile/> : <ContactUsWeb/>}
</div>
    )
}


export default ContactPage;